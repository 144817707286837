import * as React from "react"
import { graphql, Link } from "gatsby"

import Incident from "../components/incident"
import Layout from "../components/layout"
import Seo from "../components/seo"

const truncateString = (str, num)  => {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const { Name } = data.markdownRemark.frontmatter
  const {html, excerpt} = data.markdownRemark
  const { previous, next } = data
  return (
    <Layout location={location} incident={Name}>
      <Seo
        title={Name}
        description={excerpt}
        pageContext={pageContext}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
       <Incident incident={data.markdownRemark.frontmatter}/>
       <hr />
       <h2>What happened?</h2>
        <section
          dangerouslySetInnerHTML={{ __html: html }}
          itemProp="articleBody"
        />
        <hr />
       
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`${previous.fields.slug}`} rel="prev">
                ← {truncateString(previous.frontmatter.Name,50)}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`${next.fields.slug}`} rel="next">
                {truncateString(next.frontmatter.Name,50)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        metaTitle
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        Attack
        Name
        Date
        Group
        CyberOperation
        ReasonablyExpectedToCauseDeathInjuryToPeopleObjects
      }
      html
      excerpt(pruneLength: 160)
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
       Name
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
       Name
      }
    }
  }
`
